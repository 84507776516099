<template>
  <div class="comment-container">
    <img v-if="comment.avatar && comment.avatar !== '0'" class="avatar" :class="subComment && 'sub'" :src="comment.avatar" alt="">
    <span v-else class="avatar"><span class="el-icon-user-solid"></span></span>
    <div class="comment-detail">
      <div class="name">{{comment.name}}</div>
      <p class="comment-content">{{comment.context}}</p>
      <p><span class="time">{{comment.addTime}}</span><span v-if="!subComment" @click="reply" class="reply">{{!uname ? '登录后' : ''}}回复</span></p>
      <div :class="replyInputShow && 'active'" class="comment-action-wrapper">
        <div class="comment-input">
          <textarea v-model.trim="words" name="" ref="input" id="" :placeholder="'正在回复' + comment.name" rows="10"></textarea>
        </div>
        <span class="comment-btn">
          <el-button size="mini" :disabled="!words" type="primary" @click="replySubmit">发表评论</el-button>
        </span>
      </div>
      <div>
        <Comment v-for="c in comment.replyComments" :key="c.id" :comment="c" subComment />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: "Comment",
  props: {
    comment: Object,
    subComment: Boolean,
    uname: String
  },
  setup (props, { emit }) {
    const words = ref('')
    const replyInputShow = ref(false)
    const input = ref(null)
    const router = useRouter()

    const reply = () => {
      if (!props.uname) {
        return router.push('/login')
      }
      replyInputShow.value = true
      input.value.focus()
    }
    const replySubmit = () => {
      emit('comment', words.value)
      replyInputShow.value = false
      words.value = ''
    }
    return {
      words,
      replyInputShow,
      reply,
      replySubmit,
      input
    }
  }
};
</script>


<style scoped lang="stylus">
.comment-container {
  display: flex;
  padding: 20px;
  padding-bottom: 0;
}

.avatar {
  width: 50px;
  height: 50px;
  line-height 50px;
  font-size 28px;
  text-align center;
  color: #888;
  border-radius: 50%;
  background-color: #eee;
  margin-right: 20px;

  &.sub {
    width: 40px;
    height: 40px;
  }
}

.comment-detail {
  flex: 1;
  position: relative;
}

.name {
  font-size: 15px;
  color: #666;
}

.comment-content {
  color: #888;
}

.time, .reply {
  font-size: 12px;
  color: #aaa;
}

.reply {
  margin-left: 10px;
  cursor: pointer;
}

.comment-action-wrapper {
  display: flex;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  margin-top: 10px;

  &.active {
    height: 100px;
  }
}

.comment-input {
  flex: 1;
  position: relative;

  .login-tip {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45%;
  }
}

textarea {
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  padding: 5px;
  height: 80px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  resize: none;

  &:focus {
    outline: none;
  }
}

.comment-btn {
  margin-left: 20px;
}
</style>
